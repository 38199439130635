body {
    margin: 0!important;
    height: 100vh!important;
}

#root {
    height: 100vh;
}
/* App container */
.app-container {
    height: 100vh;
}

.content-container {
    height: 100vh;
}

.full-center-page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}