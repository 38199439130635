.animate-gradient-x {
  background-size: 200% auto;
  animation: gradient-x 3s linear infinite;
}

.animate-highlight {
  animation: highlight 2s ease-in-out infinite;
}

.animate-shine {
  animation: shine 3s infinite;
}

.animate-cursor {
  animation: blink 0.75s step-end infinite;
}

.animate-underline {
  animation: underline 2s ease-in-out infinite;
}

.animate-wave {
  display: inline-block;
  animation: wave 2s ease-in-out infinite;
}

.animate-pulse-text {
  animation: pulse-text 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes gradient-x {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

@keyframes highlight {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.7; }
}

@keyframes shine {
  0% { transform: translateX(-100%) skewX(-12deg); }
  100% { transform: translateX(200%) skewX(-12deg); }
}

@keyframes underline {
  0% {
    background-position: right bottom;
  }
  100% {
    background-position: left bottom;
  }
}

@keyframes wave {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@keyframes pulse-text {
  0%, 100% { color: rgb(139, 92, 246); }
  50% { color: rgba(139, 92, 246, 0.5); }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  from, to { border-color: transparent }
  50% { border-color: rgb(139, 92, 246) }
}