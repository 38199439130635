@tailwind base;
@tailwind components;
@tailwind utilities;

.drop-shadow-glow-red {
  filter: drop-shadow(0 0 8px rgba(239, 68, 68, 0.5));
}

.drop-shadow-glow-purple {
  filter: drop-shadow(0 0 8px rgba(167, 139, 250, 0.5));
}

/* Dans votre fichier CSS global */
:root {
  /* Variables de couleur pour le thème clair */
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --text-primary: #1a1a1a;
  --text-secondary: rgba(0, 0, 0, 0.6);
  --border-color: rgba(0, 0, 0, 0.1);
  --border-hover: rgba(0, 0, 0, 0.2);
  --card-bg: rgba(0, 0, 0, 0.02);
  --card-hover: rgba(0, 0, 0, 0.05);
  --purple-bg: rgba(139, 92, 246, 0.1);
  --purple-hover: rgba(139, 92, 246, 0.15);
  --overlay-color: rgba(255, 255, 255, 0.7);
}

[class='dark'] {
  /* Variables de couleur pour le thème sombre */
  --bg-primary: #0A0A0F;
  --bg-secondary: #111827;
  --text-primary: #ffffff;
  --text-secondary: rgba(255, 255, 255, 0.6);
  --border-color: rgba(255, 255, 255, 0.1);
  --border-hover: rgba(255, 255, 255, 0.2);
  --card-bg: rgba(255, 255, 255, 0.05);
  --card-hover: rgba(255, 255, 255, 0.08);
  --purple-bg: rgba(139, 92, 246, 0.2);
  --purple-hover: rgba(139, 92, 246, 0.3);
  --overlay-color: rgba(0, 0, 0, 0.7);
}

/* Classes utilitaires pour le thème */
.theme-bg {
  background-color: var(--bg-primary);
}

.theme-bg-secondary {
  background-color: var(--bg-secondary);
}

.theme-text {
  color: var(--text-primary);
}

.theme-text-secondary {
  color: var(--text-secondary);
}

.theme-border {
  border-color: var(--border-color);
}

.theme-border-hover {
  border-color: var(--border-hover);
}

.theme-card {
  background-color: var(--card-bg);
}

.theme-card-hover {
  background-color: var(--card-hover);
}

.theme-purple-bg {
  background-color: var(--purple-bg);
}

.theme-purple-hover {
  background-color: var(--purple-hover);
}

.theme-border-purple {
  border-color: rgba(139, 92, 246, 0.3);
}

/* Ajustements pour les gradients selon le thème */
.theme-gradient-text {
  @apply bg-clip-text text-transparent;
  background-image: var(--gradient-text);
}

[class='light'] .theme-gradient-text {
  --gradient-text: linear-gradient(to right, #1a1a1a, #4a4a4a);
}

[class='dark'] .theme-gradient-text {
  --gradient-text: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.8));
}